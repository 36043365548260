<script setup lang="ts">
import type { PropType } from "vue";
import type { IText } from "~/composable/text.type";
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";

const textShow = ref(false);

defineProps({
  items: {
    type: Object as PropType<IText>,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <div class="container m-auto">
    <div class="page-text">
      <div
        class="page-text__content"
        :class="{ 'page-text__content-active': textShow }"
      >
        <LazyText :items="items" :title="title" :description="description" />
      </div>
      <div class="page-text__actions">
        <a href="#" @click.prevent="textShow = !textShow">
          <EqZaimIcon
            :icon-name="textShow ? 'arrow-up-circle' : 'arrow-down-circle'"
            class="text-[20px]"
          ></EqZaimIcon>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.page-text {
  @apply bg-secondary-gray-50-50 p-[16px] pb-[48px] bg-secondary-gray-50 rounded-mobile-external relative text-normal-XS -mt-sm-block
  md:p-[32px] md:pb-[32px] md:rounded-desktop-internal md:text-normal-S-regular md:-mt-md-block
  xl:-mt-block;
  &__content {
    @apply line-clamp-5 md:line-clamp-none;
    &-active {
      @apply line-clamp-none;
    }
  }
  &__actions {
    @apply md:hidden absolute left-[16px] bottom-[16px] text-secondary-blue-green cursor-pointer;
  }
}
</style>
